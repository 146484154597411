import type { Location, MetaDescriptor } from '@remix-run/react';
import type { SeoComponent } from '@cardo/types';
import { getAppUrl } from './utils';

export function generateMetaFromSeoComponent(
  seo?: SeoComponent
): MetaDescriptor[] {
  if (seo == null) {
    return [];
  }

  const facebook = seo.metaSocial.find(
    (platform) => platform.socialNetwork === 'Facebook'
  );
  const twitter = seo.metaSocial.find(
    (platform) => platform.socialNetwork === 'Twitter'
  );

  const facebookMeta = [
    { name: 'og:type', content: 'website' },
    { name: 'og:title', content: facebook?.title || seo.metaTitle },
    {
      name: 'og:description',
      content: facebook?.description || seo.metaDescription,
    },
    {
      name: 'og:image',
      content:
        facebook?.image.data?.attributes?.url ||
        seo.metaImage.data?.attributes?.url ||
        undefined,
    },
    { name: 'og:site_name', content: 'Cardonomics' },
  ];

  const twitterMeta = [
    { name: 'twitter:title', content: twitter?.title || seo.metaTitle },
    {
      name: 'twitter:description',
      content: twitter?.description || seo.metaDescription,
    },
    {
      name: 'twitter:image',
      content:
        twitter?.image.data?.attributes?.url ||
        seo.metaImage.data?.attributes?.url ||
        undefined,
    },
  ];

  return [
    { name: 'title', content: seo.metaTitle },
    { name: 'description', content: seo.metaDescription },
    {
      name: 'image',
      content: seo.metaImage.data?.attributes?.url || undefined,
    },
    { name: 'robots', content: seo.metaRobots },
    { name: 'keywords', content: seo.keywords },
    ...facebookMeta,
    ...twitterMeta,
  ];
}

export function generateOgUrlMetaDescriptor(
  location: Location
): MetaDescriptor {
  return { name: 'og:url', content: `${getAppUrl()}${location.pathname}` };
}
